@import '../../../sass/variables';

.chatContainer {
  position: relative;
  min-width: 450px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: auto;
  width: 100%;
  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    z-index: -10;
    max-width: 100%;
    transition: opacity 0.5s ease, visibility 0s linear 0.5s;

    &.visible {
      z-index: 11000;
      transition: opacity 0.5s ease;
      min-width: unset;
      max-width: 100%;
      width: 100%;
    }

    &.active {
      display: flex;
      width: 100vw;
      /* height: 100vh; */
      position: unset;
      min-width: unset;
      max-width: unset;
    }
  }

  &.mobile {
    min-width: 300px;
    position: fixed;
    z-index: 9999;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  &.show {
    background: $backgroundColorMob;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.menuTop {
  position: absolute;
  top: -35px;
  left: 18px;
  display: flex;

  &.mob {
    top: 10px;
  }
}

.menuTopLeft {
  position: absolute;
  top: -35px;
  right: 16px;
  display: flex;
  @media (max-width: 1024px) {
    top: 10px;
  }
}

.help {
  background: url('../../../assets/images/_.svg') center no-repeat;
  background-size: contain;
  width: 13px;
  height: 24px;
}

.modal {
  position: absolute;
  top: 70px; /* Отступ от кнопки, можно настроить */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 257px;
  max-width: 339px;
  width: 80vw;
  padding: 15px;

  @media (max-width: 1024px) {
    top: 60px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 18px;
  line-height: 23.4px;
  font-family: $fontFamily;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  cursor: pointer;
  margin: 10px 0;
}

ul li a {
  text-decoration: none;
}

.muteContainer,
.restartContainer,
.toggleContainer {
  display: inline-block;
  cursor: pointer;
}

.muteContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  object-fit: contain;
  z-index: 500;
  justify-content: center;
  margin-right: 6px;
  border-radius: 50%;

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }
}

.muteBack {
  display: flex;
  width: 47px;
  height: 47px;
  background-color: $buttonBackground;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    width: 50px;
    height: 50px;
  }

  @media (hover: hover) {
    &:hover {
      background: $buttonBackgroundHover;
    }
  }

  @media (hover: none) {
    &:active {
      background: $buttonBackgroundHover;
    }
  }

  &.none {
    margin-right: 12px;
  }

  &.skeleton {
    background: #d9d9d9;
    color: #d9d9d9;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &.active {
    background: #7856C1;
  }



  &.restartContainer:hover {
    .restart {
      background: url('../../../assets/images/reloadW.svg') no-repeat center;
    }
  }
}

.mute {
  display: flex;
  width: 20px;
  height: 20px;
  background: url('../../../assets/images/VolumeUp.svg') no-repeat center center;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease;

  &.active {
    background: url('../../../assets/images/VolumeOff.svg') no-repeat center center;
  }

  // Для устройств, поддерживающих hover
  @media (hover: hover) {
    &.active {
      background: url('../../../assets/images/VolumeOff.svg') no-repeat center center;
    }
  }

  // Для устройств, где hover не поддерживается (мобильные устройства)
  @media (hover: none) {
    &:active {
      background: url('../../../assets/images/VolumeUpHover.svg') no-repeat center center;
    }

    &.active {
      background: url('../../../assets/images/VolumeOff.svg') no-repeat center center;
      &:active {
        background: url('../../../assets/images/VolumeOff.svg') no-repeat center center;
      }
    }
  }
}

.restartContainer {
  cursor: pointer;
  border: none;
  outline: none;
  aspect-ratio: 1;
  width: 47px;
  color: #fff;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  z-index: 10000;
  height: 47px;

  &:hover {
    .restart {
      background: url('../../../assets/images/reloadW.svg') no-repeat center;
    }
  }

  @media (max-width: 1024px) {
    width: 47px;
    height: 47px;
  }
}

.restart {
  background: url('../../../assets/images/reload.svg') no-repeat center;
  width: 20px;
  height: 22px;

  &:hover {
    background: url('../../../assets/images/reloadW.svg') no-repeat center;
  }
}

.chatContainerHistory {
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-width: none;
  height: calc(100vh - 180px);
  /* flex-grow: 1; */
  overflow-y: auto;
  padding: 10px 0;
  margin-bottom: 5px;
  justify-content: flex-start;
  @media (max-width: 1024px) {
    max-height: 70%;
  }
  &.mob {
    padding-bottom: 22px;
    display: none;
  }

  &.show {
    display: flex;
  }

  .chatContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .message {
      width: fit-content;
      margin-bottom: 10px;
      padding: 12px 18px;
      border-radius: 10px;
      max-width: 70%;
      word-wrap: break-word;
      box-shadow: $buttonShadow;
      line-height: 23.4px;
      font-family: $fontFamily;
      font-size: 18px;
      font-weight: 400;

      @media (max-width: 1024px) {
        font-size: 16px;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 10px;
        line-height: 20.8px;
        max-width: 70%;
        word-wrap: break-word;
      }

      &.system {
        align-self: flex-start;
        border-radius: 0px 12px 12px 12px;
        background-color: $messageSystemColor;
        margin-right: auto;
        text-align: left;
        color: $fontPrimaryColor;

        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 18.2px;

          margin-bottom: 10px;

          &.starter {
            max-width: 100%;
            width: 100%;
          }

          & h1,
          h2,
          h3,
          h4,
          h5 {
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0.5em;
          }

          & strong {
            font-weight: bold;
          }

          & ul,
          & ol {
            margin-bottom: 1em;
          }

          & ol {
            list-style-type: decimal;
          }

          & li {
            margin-bottom: 0.5em;
          }

          & pre {
            background-color: #efefef;
            padding: 10px;
            border-radius: 4px;
            overflow-x: auto;
          }
        }
      }

      &.user {
        margin-right: 3px;
        align-self: flex-end;
        border-radius: 12px 0px 12px 12px;
        background-color: $messageUserColor;
        margin-left: auto;
        text-align: right;
        font-size: 18px;
        color: $fontSecondCOlor;
        line-height: 23.4px;
      }

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 20.8px;
      }

      &.skeleton {
        background: #d9d9d9;
        color: #d9d9d9;
      }
    }

    .starterQuestions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      overflow: visible;

      &.active {
        display: none;
      }

      .starterQuestion {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: $starterQuestionColor;
        font-family: $fontFamily;
        font-size: $fontSize;
        font-size: 18px;
        font-weight: 400;
        line-height: 20.8px;

        color: $fontSecondCOlor;
        border: none;
        border-radius: 10px;
        padding: 12px 18px;
        margin: 5px 0;
        cursor: pointer;
        transition: background-color 0.3s;
        box-shadow: $buttonShadow;
        overflow: visible;

        &.skeleton {
          color: #d9d9d9;
          background: #d9d9d9 !important;
        }

        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 18.2px;
          height: 39px;
        }

        &:disabled {
          background: #dfdfdf;
          pointer-events: none;
        }

        &:hover {
        }

        &:active {
          background-color: $fontPrimaryColor;
          color: $fontSecondCOlor;
        }

        .starterQuestionArrow {
          background: url('../../../assets/images/Arrow2.svg') no-repeat center;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.messageContainer {
  display: flex;
  position: relative;

  .messageText {
    width: 100%;
  }

  a {
    color: #ffffff;
    text-decoration: underline; /* Добавляем подчеркивание */
  }
}

.selectedReaction {
  background: $backgroundColorReaction;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  font-size: 12px;
}

.likeIcon.active {
  background: url('../../../assets/images/like21.svg') center no-repeat;
  width: 25px;
  height: 25px;
}

.dislikeIcon.active {
  background: url('../../../assets/images/like21.svg') center no-repeat;
  transform: rotateX(180deg);
  width: 14px;
  height: 14px;
}

.reactionContainer {
  display: flex;
  margin-bottom: 5px;

  .likeIcon {
    background: url('../../../assets/images/like21.svg') center no-repeat;
    width: 25px;
    height: 25px;
  }

  .dislikeIcon {
    background: url('../../../assets/images/like21.svg') center no-repeat;
    transform: rotateX(180deg);
    width: 25px;
    height: 25px;

    &.active {
      background: url('../../../assets/images/like21.svg') center no-repeat;
      transform: rotateX(180deg);
      width: 25px;
      height: 25px;
    }
  }

  .like {
    background: $backgroundColorReaction;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 12px;

    &:active {
      background: $buttonBackgroundActive;
    }
  }
}

.dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #fff; /* Белый цвет точек */
  border-radius: 50%;
  margin-left: 2px; /* Расстояние между точками */
  animation: bounce 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}

.chatContainerInput {
  height: 82px;
  padding: 18px;
  position: relative;
  display: flex;
  background: $fontPrimaryColor;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 1024px) {
    height: 75px;
    position: relative;
    margin-bottom: 5px;

    padding: 8px;
    display: flex;
    /* height: 96px; */
    position: relative;
    /* align-items: flex-end; */

    &.mob {
      padding: 0 10px;
      z-index: 125;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
    }
  }

  &.round {
    border-top-right-radius: 0;
  }

  &.active {
    background: $fontPrimaryColor;
  }

  &.hidden {
    background: #ddd;
  }
}

.menuMobile {
  width: 79px;
  height: 34px;
  margin-bottom: 10px;
  position: absolute;
  top: -45px;

  &.skeleton {
    background: #d9d9d9;

    button {
      background: #d9d9d9;
      color: #d9d9d9;
    }
  }

  button {
    margin-bottom: 10px;
    display: flex;
    width: 79px;
    height: 34px;
    outline: none;
    color: #fcfcfc;
    font-family: 'Afacad', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    background: #eb3b96;
    padding: 10px;
    border-radius: 10px 10px;
    align-items: center;
    justify-content: center;
  }

  button:disabled {
  }
}

textarea,
input {
  outline: none;
  margin: 0;
  border: none;
}

textarea {
  font-family: 'Afacad', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23.4px;
  resize: none; /* Отключает возможность изменения размера вручную */
  overflow-y: scroll; /* Оставляем прокрутку по вертикали */
  scrollbar-width: none; /* Firefox: убирает скроллбар */
  -ms-overflow-style: none; /* IE и Edge: убирает скроллбар */
}

textarea::-webkit-scrollbar {
  display: none; /* Убирает скроллбар для Chrome, Safari */
}

.chatContainerTextarea {
  max-width: 90%;
  flex: 1 1;
  align-content: center;
  height: 52px;
  border: none;
  background: $fontPrimaryColor;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: $fontSize;
  font-family: $fontFamily;
  font-weight: 400;
  line-height: 20.8px;
  color: $fontSecondCOlor;
  resize: none;
  flex-grow: 1;
  border-radius: 10px;
  outline: none;
  width: 100%;

  @supports (-webkit-touch-callout: none) {
    .chatContainerTextarea {
      display: block; /* Убираем flex, так как инпуты могут не поддерживать flex-контейнеры корректно */
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    /* outline: none; */
    height: 46px !important;
    margin-right: 5px;
    width: 60% !important;
    align-items: center;
    align-content: start;
    font-size: 16px;
    padding: 5px;
    line-height: 14.8px;
    padding-top: 10px;

    &::-webkit-input-placeholder {
      color: #000;
      display: block;
      align-items: center;
    }

    /* Для Firefox */
    &::-moz-placeholder {
      align-items: center;
      color: #000;
    }

    /* Для старых версий Firefox */
    &:-moz-placeholder {
      align-items: center;
      color: #000;
    }

    /* Для IE */
    &:-ms-input-placeholder {
      align-items: center;
      color: #000;
    }
  }

  &.skeleton {
    background: #dddddd;
  }

  &::placeholder {
    font-family: $fontFamily;
    color: $fontSecondCOlor;
    align-items: center;
    display: flex;
    flex-direction: center;
    margin: 0 auto;
  }

  &.hidden {
    background: #ddd;
    color: #ccc;
  }

  &.hidden::placeholder {
    color: #ccc;
  }

  &:focus {
    background: $fontPrimaryColor;

    &::placeholder {
      color: #000;
    }
  }
}
.chatContainerTextarea:focus .chatContainerSendButton {
  background: #000000;
}

.chatContainerTextarea::placeholder {
  color: #afafaf;
}

.holdMicro {
  max-width: 282px;
  text-align: center;
  position: absolute;
  z-index: 99;
  bottom: 100%;
  right: 0;
  padding: 8px;
  font-family: $fontFamily;
  font-size: 16px;
  color: $fontPrimaryColor;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: $messageSystemColor;
  transition: opacity 0.5s ease; // Плавный переход для исчезновения

  @media (max-width: 1024px) {
    max-width: 182px;
    font-size: 10px;
    text-align: start;
    bottom: 100%;
    line-height: 13px;

    .triangle {
      right: 21px;
    }
  }

  &.fadeOut {
    opacity: 0; // Устанавливаем 0 для скрытия блока
  }

  .triangle {
    position: absolute;
    bottom: -4px;
    right: 30px;
    rotate: 180deg;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $backgroundColorNotion;

    @media (max-width: 1024px) {
      right: 21px;
    }
  }
}

.recordStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flashingButton {
  width: 9px; /* Set width to 9px */
  height: 9px; /* Set height to 9px */
  background-color: #cc3939; /* Set background color */
  border: none;
  border-radius: 50%; /* Make it circular */
  color: white; /* Text color, if needed */
  animation: flash 1s infinite; /* Flash animation */
  margin-right: 4px;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.5; /* Half visible for flashing effect */
  }
}

.timerRecord {
  font-family: $fontFamily;
  color: $fontSecondCOlor;
  font-size: 16px;
  line-height: 20.8px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 15.6px;
  }
}

.textRecord {
  font-family: $fontFamily;
  color: $fontSecondCOlor;
  font-size: 16px;
  line-height: 20.8px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 15.6px;
  }
}

.inputButton {
  display: flex;
}

#skip {
  margin-right: 5px;
}

.stopButtonContainer {
  background: $buttonBackgroundActive;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading {
    background: $buttonBackgroundActive;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    display: flex;
  }
}

.stopButton {
  background: #fff;
  width: 10px;
  height: 10px;
  border: 0;
  outline: none;

  &.loading {
    background: url(../../../assets/images/loader.svg) no-repeat center;
    width: 25px;
    height: 25px;
    border: 0;
    outline: none;
  }
}

.recognizeButton {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  background: $buttonBackgroundActive;
  width: 46px;
  height: 46px;

  -webkit-touch-callout: none; /* Отключает контекстное меню в Safari на iOS */
  user-select: none; /* Отключает выделение текста */

  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
  }

  &:hover {
    background: $buttonBackgroundHover;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }
}

.chatContainerTextarea::placeholder {
  font-size: 16px;
  color: #afafaf;
}

.chatContainerSendButton {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &.hidden {
    background-color: #ddd;
  }
}

.circleMicrophone {
  background: url('../../../assets/images/Voice.svg') no-repeat center; /* Icon background */
  width: 25px; /* Button width */
  height: 25px; /* Button height */
  border-radius: 50%; /* Make it circular */
  transition: transform 0.3s ease; /* Smooth transition for scale */
}

//#recognize-button.active {
//  box-shadow: 0 0 0 4px rgba(120, 86, 193, 0.5); /* Outer border effect when active */
//  animation: pulse 1s infinite; /* Flashing effect */
//}

:global {
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}

.sendButton {
  background-color: $buttonBackgroundActive;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-left: 5px;
  transition: all 0.2s linear;

  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }

  svg {
    width: 25px;
    height: 21px;
  }
  .svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: $buttonBackgroundHover;
  }

  &.hidden {
    background-color: #ddd;
  }

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
}
