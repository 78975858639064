.privacyContainer {

  background: #fff;
  padding: 0 181px;
  padding-top: 60px;

  @media (max-width: 1024px) {
    padding: 0 16px;
    overflow-y: auto;
  }

  .backAbs {
    padding: 20px;
    color: #7856C1;
    font-family: 'Afacad', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  cursor: pointer;
  }
}



.privacyContent {
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.h1 {
  font-family: 'Afacad', sans-serif;
  font-weight: 400;
  font-size: 40px;
  color: #171717;
  text-align: left;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    font-size: 24px;
    font-weight: 400;
    line-height: 26.4px;
    text-align: left;

  }
}

.p {
  margin-bottom: 30px;
  font-family: 'Afacad', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 28.8px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;


  }
}

.bold {
  font-family: 'Afacad', sans-serif;
  font-weight: bold;
}

.agreementText {
  margin: 20px 0;
  font-size: 16px;
  line-height: 1.6;
  color: #444;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.agreeButton {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #45a049;
  }
  &:disabled {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
}

.agreementList {
  padding-left: 20px;
  list-style-type: decimal; /* Стиль нумерации */
  margin-top: 10px;
}

.h3 {
  color: #171717;
  font-family: 'Afacad', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;

  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    text-align: left;



  }

}

.li {
  font-family: 'Afacad', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28.8px;
  text-align: left;
  color: #171717;
  margin-bottom: 10px;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;


  }
}

.ol {
  padding-left: 20px;
}

.back {
  width: 163px;
  margin: 30px 0;
  background-color: #7856C1;
  color: #fff;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  border-radius: 8px; /* округление нижних углов */
  cursor: pointer;
  text-align: center;



  &:hover:enabled {
    background-color: #9B7AE2;
  }
}

