$backgroundColor: #ececec;
$backgroundColorMob: #ececec;
$primaryColor: rgba(27, 27, 27, 0.768627451);
$starterQuestionColor: #fcfcfc;
$messageSystemColor: #7856c1;
$messageUserColor: #fcfcfc;
$fontPrimaryColor: #fcfcfc;
$fontSecondCOlor: #171717;
$fontFamily: 'Afacad', sans-serif;
$fontSize: 16px;
$buttonShadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
$transiton: all 0.2s linear;
$inputBackground: #fcfcfc;
$backgroundColorReaction: #ffffff;
$buttonBackground: #fcfcfc;
$buttonBackgroundActive: #7856c1;
$buttonBackgroundHover: #9b7ae2;
$buttonBackgroundDisabled: #c8c8c8;
$mobileButton: #eb3b96;
$backgroundColorNotion: rgba(27, 27, 27, 0.768627451);