$backgroundColor: #ececec;
$backgroundColorMob: #ececec;
$primaryColor: rgba(27, 27, 27, 0.768627451);
$starterQuestionColor: #fcfcfc;
$messageSystemColor: #7856c1;
$messageUserColor: #fcfcfc;
$fontPrimaryColor: #fcfcfc;
$fontSecondCOlor: #171717;
$fontFamily: 'Afacad', sans-serif;
$fontSize: 16px;
$buttonShadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
$transiton: all 0.2s linear;
$inputBackground: #fcfcfc;
$backgroundColorReaction: #ffffff;
$buttonBackground: #fcfcfc;
$buttonBackgroundActive: #7856c1;
$buttonBackgroundHover: #9b7ae2;
$buttonBackgroundDisabled: #c8c8c8;
$mobileButton: #eb3b96;
$backgroundColorNotion: rgba(27, 27, 27, 0.768627451);

* {
  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  text-transform: none;
  color: inherit;
  cursor: pointer;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea,
input {
  outline: none;
  margin: 0;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-clear {
  display: none;
}

input:-internal-autofill-selected {
  background-color: $inputBackground !important;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.cookie-banner {
  position: fixed;
  bottom: 50vh;
  /* left: 50%; */
  width: fit-content;

  background-color: #7856c1;
  border-radius: 10px;
  color: #fff;
  font-family: $fontFamily;
  font-size: 18px;
  line-height: 1.5;
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  button {
    background-color: #fff;
    border: 1px #fff solid;
    color: #000;
    font-family: $fontFamily;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 66px;
    transition: all 0.2s linear;

    &:hover {
      color: #fff;
      background-color: #9b7ae2;
    }

    &:active {
      color: #fff;
      background-color: #7856c1;
    }

    &:focus {
      color: #fff;
      background-color: #7856c1;
    }
  }
}

/* Затемнение фона */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 55555;
  display: block; /* Скрыто по умолчанию */
}

body {
  line-height: 1.4;
  font-weight: 400;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: $fontFamily;
  background-color: $fontPrimaryColor;
  -webkit-user-select: none; /* Отключает выделение текста */
  user-select: none; /* Отключает выделение текста */
  touch-action: manipulation;
}

#content {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: $backgroundColor;
}

#video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    position: unset;
    z-index: 100;

    &.back {
      display: none;
    }
  }

  .skeleton {
    position: absolute;
    background: url('../assets/images/skeleton.svg') center no-repeat;
    width: 100%;
    height: 100%;
    z-index: 121;

    @media (max-width: 1024px) {
      background: url('../assets/images/skeleton-mob.svg') center no-repeat;
      width: 100%;
      height: 100%;
      z-index: 121;
    }
  }

  &.skeleton {
    position: relative;
    background: #ffffff;
    z-index: 121;
    @media (max-width: 1024px) {
      position: absolute;
      background: #ffffff;
      z-index: 121;
    }
  }

  #talk-video,
  #start-video,
  #video-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.skeleton {
      opacity: 0;
    }
  }

  .talk-video {
    z-index: -1124;
    transition: all 0.2s linear;
    &.skeleton {
      opacity: 0;
    }

    &.active {
      z-index: 111;
    }
  }

  .video-body {
    z-index: 111;
    transition: all 0.2s linear;
    &.skeleton {
      opacity: 0;
    }

    &.active {
      z-index: -1;
    }

    &.hidden {
      z-index: 100;
    }
  }

  .start-video {
    z-index: 120;
    transition: all 0.2s linear;

    &.skeleton {
      opacity: 0;
    }

    &.hidden {
      z-index: -123123;
    }
  }
}

.chat-container {
  position: relative;
  min-width: 450px;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: auto;
  width: 100%;
  z-index: 2;

  &.mobile {
    min-width: 300px;
    position: fixed;
    z-index: 9999;
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  &.show {
    background: $backgroundColorMob;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.menuTop {
  position: absolute;
  top: -15px;
  left: 18px;
  display: flex;

  &.mob {
    top: 10px;
  }
}

.muteContainer,
.restartContainer,
.toggleContainer {
  display: inline-block;
  cursor: pointer;
}

.muteContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  object-fit: contain;
  z-index: 500;
  justify-content: center;
  margin-right: 6px;
  border-radius: 50%;

  &:hover {
    background: $buttonBackgroundHover;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }
}

.muteBack {
  display: flex;
  width: 36px;
  height: 36px;
  background-color: $buttonBackground;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: $buttonBackgroundHover;

    .mute {
      background: url('./images/VolumeUpHover.svg') no-repeat center center;
    }
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &.active {
    background: $buttonBackgroundActive;

    &:hover {
      background: $buttonBackgroundHover;

      .mute {
        background: url('./images/VolumeOff.svg') no-repeat center center;
      }
    }
  }

  &.restart:hover {
    #restart {
      background: url('./images/reloadW.svg') no-repeat center;
    }
  }
}

.mute {
  display: flex;
  width: 20px;
  height: 20px;
  background: url('./images/VolumeUp.svg') no-repeat center center;
  justify-content: center;
  align-items: center;

  &:hover {
    background: url('./images/VolumeUpHover.svg') no-repeat center center;
  }

  &.active {
    fill: black;
    background: url('./images/VolumeOff.svg') no-repeat center center;

    &:hover {
      background: url('./images/VolumeOff.svg') no-repeat center center;
    }
  }
}

.chat-container__history {
  max-height: 100%;
  display: flex;
  flex-direction: column-reverse;
  scrollbar-width: none;
  height: calc(100vh - 150px);
  /* flex-grow: 1; */
  overflow-y: auto;
  padding: 10px 0;
  margin-bottom: 5px;
  justify-content: flex-start;

  &.mob {
    padding-bottom: 25px;
    display: none;
  }

  &.show {
    display: flex;
  }

  .chat-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .message {
      margin-bottom: 10px;
      padding: 12px 18px;
      border-radius: 10px;
      max-width: 70%;
      word-wrap: break-word;
      box-shadow: $buttonShadow;
      line-height: 20.8px;
      font-family: $fontFamily;
      font-size: 16px;
      font-weight: 400;

      &.system {
        align-self: flex-start;
        border-radius: 0px 12px 12px 12px;
        background-color: $messageSystemColor;
        margin-right: auto;
        text-align: left;
        color: $fontPrimaryColor;
      }

      &.user {
        margin-right: 3px;
        align-self: flex-end;
        border-radius: 12px 0px 12px 12px;
        background-color: $messageUserColor;
        margin-left: auto;
        text-align: right;
        font-size: $fontSize;
        color: $fontSecondCOlor;
      }

      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 18.2px;
      }
    }

    .starter-questions {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      overflow: visible;

      &.active {
        display: none;
      }

      .starter-question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: $starterQuestionColor;
        font-family: $fontFamily;
        font-size: $fontSize;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;

        color: $fontSecondCOlor;
        border: none;
        border-radius: 10px;
        padding: 12px 18px;
        margin: 5px 0;
        cursor: pointer;
        transition: background-color 0.3s;
        box-shadow: $buttonShadow;
        overflow: visible;

        @media (max-width: 1024px) {
          font-size: 14px;
          line-height: 18.2px;
        }

        &:disabled {
          background: #dfdfdf;
          pointer-events: none;
        }

        &:hover {
        }

        &:active {
          background-color: $fontPrimaryColor;
          color: $fontSecondCOlor;
        }

        .starter-questionArrow {
          background: url('images/Arrow2.svg') no-repeat center;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

.menuMobile {
  display: flex;
  position: absolute;
  right: 0;
  top: -29px;
  width: 75px;
  height: 29px;
  background: rgba(235, 59, 150, 0.5);
  border-radius: 10px 10px 0 0;
  align-items: flex-end;
  justify-content: center;

  button {
    display: flex;
    width: 67px;
    height: 25px;
    outline: none;
    color: #fcfcfc;
    font-family: 'Afacad', sans-serif;
    font-weight: 500;
    font-size: 12px;
    background: #eb3b96;
    padding: 10px;
    border-radius: 10px 10px 0 0;
    align-items: center;
    justify-content: center;
  }

  button:disabled {
  }
}

.chat-container__input {
  height: 72px;
  padding: 18px;
  position: relative;
  display: flex;
  background: $fontPrimaryColor;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;

  &.active {
    background: $fontPrimaryColor;
  }

  &.hidden {
    background: #ddd;
  }
}

.recornbtns {
  display: flex;
}

.chat-container__textarea {
  max-width: 90%;
  flex: 1 1;
  align-content: center;
  height: 52px;
  border: none;
  background: $fontPrimaryColor;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: $fontSize;
  font-family: $fontFamily;
  font-weight: 400;
  line-height: 20.8px;
  color: $fontSecondCOlor;
  resize: none;
  flex-grow: 1;
  border-radius: 10px;
  outline: none;
  width: 100%;

  &::placeholder {
    font-family: $fontFamily;
    color: $fontSecondCOlor;
    align-items: center;
    display: flex;
    flex-direction: center;
    margin: 0 auto;
  }

  &.hidden {
    background: #ddd;
    color: #ccc;
  }

  &.hidden::placeholder {
    color: #ccc;
  }

  &:focus {
    background: $fontPrimaryColor;

    &::placeholder {
      color: #000;
    }
  }
}
.chat-container__textarea:focus .chat-container__send-button {
  background: #000000;
}

.chat-container__textarea::placeholder {
  color: #afafaf;
}

.chat-container__send-button {
  border-radius: 50%;
}

#recognize-button {
  background: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  background: $buttonBackgroundActive;
  width: 36px;
  height: 36px;

  &:hover {
    background: $buttonBackgroundHover;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }
}

#circleMicrophone {
  background: url('./images/Voice.svg') no-repeat center; /* Icon background */
  width: 28px;
  height: 28px;
  border-radius: 50%; /* Make it circular */
  transition: transform 0.3s ease; /* Smooth transition for scale */

  @media (max-width: 1024px) {
  }
}

//#recognize-button.active {
//  box-shadow: 0 0 0 4px rgba(120, 86, 193, 0.5); /* Outer border effect when active */
//  animation: pulse 1s infinite; /* Flashing effect */
//}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.textRecord {
  font-family: $fontFamily;
  color: $fontSecondCOlor;
  font-size: 16px;
  line-height: 20.8px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 15.6px;
  }
}

.timerRecord {
  font-family: $fontFamily;
  color: $fontSecondCOlor;
  font-size: 16px;
  line-height: 20.8px;

  @media (max-width: 1024px) {
    font-size: 12px;
    line-height: 15.6px;
  }
}

#stopMicro {
  background: url('images/krest1.svg') no-repeat center;
  width: 24px;
  height: 24px;
}

#recognize-submit {
  background: $buttonBackgroundActive;
  margin-left: 10px;

  &:hover {
    background: $buttonBackgroundHover;
  }

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &.active {
    background: $buttonBackgroundActive;
  }
}

#submitRecord {
  background: url('./images/track.svg') no-repeat center;
  width: 34px;
  height: 24px;
  background-size: 60%;
}

.inputButton {
  display: flex;
}

#skip {
  margin-right: 5px;
}

.stop-button-container {
  background: $buttonBackgroundActive;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.loading {
    background: $buttonBackgroundActive;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    display: flex;
  }
}

#send-button {
  background-color: $buttonBackgroundActive;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 5px;
  transition: all 0.2s linear;

  &:disabled {
    background: $buttonBackgroundDisabled;
  }

  &:active {
    background: $buttonBackgroundActive;
  }

  svg {
    width: 19px;
    height: 17px;
  }
  .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: $buttonBackgroundHover;
  }

  &.hidden {
    background-color: #ddd;
  }

  &.active {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chat-container__send-button {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &.hidden {
    background-color: #ddd;
  }
}

.dot {
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: #fff; /* Белый цвет точек */
  border-radius: 50%;
  margin-left: 2px; /* Расстояние между точками */
  animation: bounce 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}

.selected-reaction {
  background: $buttonBackgroundActive;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -44px;
  font-size: 12px;
}

.holdMicro {
  text-align: center;
  position: absolute;
  z-index: 99;
  bottom: 81%;
  right: 0;
  padding: 8px;
  font-family: $fontFamily;
  font-size: 14px;
  color: $fontPrimaryColor;
  border-radius: 10px;
  background: $backgroundColorNotion;
  transition: opacity 0.5s ease; // Плавный переход для исчезновения

  &.fade-out {
    opacity: 0; // Устанавливаем 0 для скрытия блока
  }

  .triangle {
    position: absolute;
    bottom: -4px;
    right: 30px;
    rotate: 180deg;
    margin-left: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid $backgroundColorNotion;
  }
}

.recordStatus {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flashing-button {
  width: 9px; /* Set width to 9px */
  height: 9px; /* Set height to 9px */
  background-color: #cc3939; /* Set background color */
  border: none;
  border-radius: 50%; /* Make it circular */
  color: white; /* Text color, if needed */
  animation: flash 1s infinite; /* Flash animation */
  margin-right: 4px;
}

@keyframes flash {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0.5; /* Half visible for flashing effect */
  }
}

@media screen and (max-width: 1024px), screen and (max-height: 768px) {
  .starter-question {
    height: 39px;
  }
  * {
    box-sizing: border-box; /* Учитывает padding и border в ширине и высоте элемента */
  }

  body {
    margin: 0;
    overflow: hidden;
    overflow-y: hidden;
  }


  #showVideo {
    display: flex;
  }

  #video-wrapper,
  .chat-container {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    flex: 1;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Отключаем горизонтальный скролл */
  }
  #content {
    /* display: unset; */
    /* width: auto;
    height: 100%;
    overflow: hidden; */
  }

  /* #video-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    max-height: 566px;
} */

  #video-wrapper.back {
    display: none;
  }

  .chat-container {
    /* background: #f0f0f0;
    min-width: unset;
    max-width: unset;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 5px;
    margin: 0; */
    padding: 0 10px;
  }

  .chat-container.active {
    display: flex;
    width: 100vw;
    /* height: 100vh; */
    position: unset;
    min-width: unset;
    max-width: unset;
  }

  .showChatContainer.hidden {
    background: #ccc;
  }

  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
  }
  .chat-container__textarea {
    display: flex;
    flex-grow: 1;
    border-radius: 25px;
    box-sizing: border-box;
    /* outline: none; */
    height: 46px !important;
    margin-right: 5px;
    width: 60% !important;
    align-items: center;
    align-content: start;
    font-size: 14px;
    line-height: 14.8px;
  }
  .chat-container__textarea::-webkit-input-placeholder {
    color: #000;
    display: block;
    align-items: center;
  }

  /* Для Firefox */
  .chat-container__textarea::-moz-placeholder {
    align-items: center;
    color: #000;
  }

  /* Для старых версий Firefox */
  .chat-container__textarea:-moz-placeholder {
    align-items: center;
    color: #000;
  }

  /* Для IE */
  .chat-container__textarea:-ms-input-placeholder {
    align-items: center;
    color: #000;
  }
  .message.system {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  .message.system h1,
  .message.system h2,
  .message.system h3 {
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }

  .message.system strong {
    font-weight: bold;
  }

  .message.system ul,
  .message.system ol {
    margin-left: 20px;
    margin-bottom: 1em;
  }

  .message.system li {
    margin-bottom: 0.5em;
  }

  .message.system pre {
    background-color: #efefef;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
  }

  .message.user {
    font-size: 16px;
  }

  .chat-container__input {
    height: 46px;
    position: relative;
    margin-bottom: 5px;
  }

  .showChat {
    display: flex;
    margin-right: 20px;
    width: 220px;
    height: 40px;
    background: #ffffff62;
    color: #522583;
    border: 2px #522583 solid;
    border-radius: 15px;
    padding: 5px;
    font-family: 'Verdana', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    justify-content: center;
  }
  .showChat:active {
    background: #fff;
  }

  .chat-container__input {
    padding: 8px;
    border-radius: 10px 0 10px 10px;
    display: flex;
    /* height: 96px; */
    position: relative;
    /* align-items: flex-end; */
  }

  @supports (-webkit-touch-callout: none) {
    .chat-container__textarea {
      display: block; /* Убираем flex, так как инпуты могут не поддерживать flex-контейнеры корректно */
    }
  }

  .chat-container__input.mob {
    padding: 0 10px;
    z-index: 125;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
  }

  .showChatContainer {
    padding: 5px 10px;
    background: #fff;
    border: 0;
    text-decoration: none;
    color: #000;
    width: fit-content;
    height: 30px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    bottom: 55px;
    border: 0px transparent solid;
  }

  .chatSvg {
    width: fit-content;
    padding: 0 10px;
  }

  .muteContainerChat {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .chatMenu {
    position: absolute;
    right: 0px;
    top: 35px;
    display: none;
  }

  .chatMenu.active {
    display: unset;
  }

  .muteContainerChatMob {
    object-fit: contain;
    width: 45px;
    height: 45px;
    background-color: #52258323;
    position: absolute;
    bottom: 7px;
    right: 10px;
    z-index: 500;
    border-radius: 50%;
  }

  .muteContainerChatMob.active {
    background-color: #522583;
  }

  .muteMob {
    margin: auto;
    margin-top: 3px;
    text-align: center;
    display: flex;
    width: 40px;
    height: 35px;
    justify-content: center;
    align-items: center;
  }

  .muteMob.active {
    fill: black;
  }

  .holdMicro {
    max-width: 400px;
    font-size: 12px;

    bottom: 98%;

    .triangle {
      right: 21px;
    }
  }
}

/* @media screen and (max-height: 932px) {
  #video-wrapper {
    height: calc(100% - 265px);
  }

  .chat-container {
    height: calc(100% - 215px);
  }
} */

@media screen and (max-width: 376px) {
  .chatMenu {
    position: absolute;
    right: 0px;
    top: 35px;
    display: none;
  }
}

@media (max-width: 1024px) {
  /* Стиль для мобильных устройств */
  #content {
    display: inline;
    position: unset;
  }

  #video-wrapper {
    position: unset;
    z-index: 100;
  }

  .toggleContainer {
    margin-left: 10px;
  }

  .fixed-buttons {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #333;
    padding: 10px;
    z-index: 1000;
    justify-content: flex-start;
  }

  .chat-container {
    width: 100%;
    height: 100%;
    z-index: -10;
    max-width: 100%;
    transition: opacity 0.5s ease, visibility 0s linear 0.5s;
  }

  .chat-container.visible {
    z-index: 11000;
    transition: opacity 0.5s ease;
    min-width: unset;
    max-width: 100%;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  /* На экранах больше 1024px скрываем фиксированные кнопки */
  .fixed-buttons {
    display: none;
  }
}

.messageContainer {
  display: flex;
  position: relative;

  .messageText {
    width: 100%;
  }

  a {
    text-decoration: underline; /* Добавляем подчеркивание */
  }
}

.reactionContainer {
  display: flex;
  position: absolute;
  bottom: -10px;
  right: -85px;

  .like {
    background: $backgroundColorReaction;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 12px;

    &:active {
      background: $buttonBackgroundActive;
    }
  }
}

.MathJax {
  overflow-x: auto;
  font-size: 1.2rem !important;
}

mjx-container[jax='CHTML'][display='true'] {
  text-align: left !important;
}

.math-wrap {
  word-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}