@import '../../../sass/variables';


.buttonCon {
  display: flex;
  flex-direction: column;
  width: 150px;
  @media (max-width: 1024px) {
    width: 100%;
  }
}
.noLink {
  margin: 0;
  margin-top: 10px;
  color: #fff;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  background-color: #fff;
  color: #7856C1;
  border: 1px solid #7856C1;
  transition: all .2s linear;

  &:hover {
    background: #7856C1;
    color: #fff;
  }
}

.cookie-banner {
  position: fixed;
  top: 10vh;
  width: 80%;
  max-width: 848px;
  min-height: 640px;
  background-color: #fff;
  border-radius: 10px;
  color: #fff;
  font-family: $fontFamily;
  font-size: 18px;
  line-height: 1.5;
  padding: 60px 30px;
  justify-content: center;
  align-items: center;
  z-index: 100000;

  @media (min-width: 1024px) and (max-height: 700px) {
    min-height: unset;
    height: 80vh;
  }


  @media (max-width: 1024px) {
    padding: 30px 18px;
    min-height: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 300;
    width: 90%;
    display: flex
  ;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    justify-content: unset;
    height: 80vh;
  }

  .cookie-text {
    flex-grow: 1;
    overflow-y: auto;
    color: #333;
    height: 450px;

    font-size: 12px;
    font-weight: 500;
    line-height: 23.4px;
    position: relative;
    padding-right: 10px; /* Отступ для скроллбара, чтобы не перекрывался текст */

    @media (max-width: 1024px) {
      flex-grow: unset;
      font-size: 10px;
      line-height: 13.4px;
      height: 60vh;
      min-height: 50%;
    }

    @media (min-width: 1024px) and (max-height: 700px) {
        max-height: 80%;
      padding-bottom: 20px;
    }


    p {
      margin-bottom: 10px;
    }

    span {
      font-weight: bold;
    }

    a {
      color: #7856C1;
    }

    scrollbar-width: thin;
    scrollbar-color: #ffffff #ECECEC;

  }

  #connect-button {
    margin: 0;
    background-color: #B3B3B3;
    color: #fff;
    border: none;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    opacity: 0.5;
    pointer-events: none;

    &:enabled {
      opacity: 1;
      pointer-events: auto;
      background-color: #7856C1;
    }

    &:hover:enabled {
      background-color: #9B7AE2;
    }
  }
}
